.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top-container {
  flex: 0;
  background: #ffffff;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 100;
}

.top {
  border-bottom: solid 1px var(--adm-border-color);
  max-width: var(--container-width);
  margin: 0 auto;
}

.top.home {
  border-bottom: none;
  background: #fbfaf5;
}

.body-container {
  flex: 1;
  display: flex;
  max-width: var(--container-width);
  margin: 45px auto 0 auto;
  padding-bottom: env(safe-area-inset-bottom);
}

.body {
  width: 100vw;
}

.bottom-container {
  padding-bottom: 60px;
}

.bottom {
  max-width: var(--container-width);
  margin: 0 auto;
  position: fixed;
  padding-bottom: env(safe-area-inset-bottom);
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  flex: 0;
  border-top: solid 1px var(--adm-border-color);
}

